<template>
    <section class="pt-8">
        <div class="container full-height p-4">
            <login-heading :back-btn="true" heading="Contact Information" size="lg">
                <template #prepend>
                    <span class="text-2 font-inter-bold fs--1 mb-4">REGISTRAION 1/4</span>
                </template>
            </login-heading>
            <div class="row">
                <div class="col-12 col-lg-4 col-md-4">
                    <validated-input placeholder="First Name" class="c-input"></validated-input>
                    <validated-input label="Last Name" class="c-input"></validated-input>
                    <validated-input label="Father's/Husband's Name" class="c-input"></validated-input>
                    <validated-input type="number" label="Fax Number" class="c-input"></validated-input>
                    <validated-text-area label="Address 1" class="c-input"></validated-text-area>
                    <btn block text="Next" @click="$router.push({name:'RegisterPanForm'})" class="mt-4 border-r--2 auth-button mb-7 py-3 d-sm-none"></btn>
                    <!--                    <btn block text="Next" class="mt-4 border-r&#45;&#45;2 auth-button py-3"></btn>-->

                </div>
                <div class="col-12 col-lg-4 col-md-4">
                    <validated-text-area label="Address 1" class="c-input"></validated-text-area>
                    <validated-input class="c-input" label="India"></validated-input>
                    <div class="fl-x-res">
                        <validated-input class="c-input mr-2" label="Kerala"></validated-input>
                        <validated-input class="c-input" label="District"></validated-input>
                    </div>
                    <validated-input type="number" placeholder="Pin/Zip" class="c-input"></validated-input>

                </div>
                <div class="col-12 col-lg-4 col-md-4">
                    <validated-input class="c-input" label="Name of Organization"></validated-input>
                    <validated-input class="c-input" label="Authorized Person"></validated-input>
                    <validated-input class="c-input" label="Designation"></validated-input>
                    <!--                    <btn block text="Next"-->
                    <!--                         class="mt-4 text-center d-lg-none border-r&#45;&#45;2 auth-button py-3 d-sm-none"></btn>-->
                    <!--                    <btn block text="Next" class="mt-4 border-r&#45;&#45;2 auth-button py-3"></btn>-->

                </div>
                <div class="col-12">
                    <span class="text-2 font-inter-medium fs--1 mt-5 op-06"># Registration Completed: 1/4</span>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import LoginHeading from '@components/login/LoginHeading';

export default {
    name: 'RegisterContactInformation',
    components: { LoginHeading }
};
</script>

<style scoped>

</style>
